<template>

<div class="home">
  <b-loading v-model="isLoading"></b-loading>
<div class="container">

<section>
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-4">Your Devices</h1>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <b-button tag="router-link" to="/add" :loading="isLoading">Add device</b-button>
      </div>
    </div>
  </div>
  <UnitCarousel @unitSelected="unitSelected" :units="unitsData"/>
</section>

<div v-if="unitsData.length>0">
<section>
  <h1 class="title">{{currentTitle}}</h1>
  <h2 class="subtitle is-6">{{currentSubtitle}}</h2>
</section>

<section>
  <!-- <b-button @click="test++">update</b-button> -->
  <div class="columns">
    <div class="column">
      <GraphCard type='power' :data="[currentData.data.power]" unit="W"/>
    </div>
   
    <template v-if="!currentData.usage">
      <div class="column">
        <GraphCard type='battery' :data="[currentData.data.battery]"  unit="%"/>
      </div>
      <div class="column">
        <GraphCard type='status' :data="currentData"/>
      </div>
    </template>

    <template v-else>
      <div class="column">
        <GraphCard type='usage' :data="[currentData.data.usage]"  unit="kWh"/>
      </div>
      <div class="column">
        <GraphCard type='devices' :data="[currentData.data.devices]"/>
      </div>
    </template>
  </div>
</section>

<!-- <section>
   <nav class="level">
    <div class="level-left">
      <div class="level-item">
        <Slider :items="['Energy','Carbon']"/>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <Slider :items="['Today','Last 7 Days','Last 30 Days','Custom']"/>
      </div>
    </div>
  </nav>
</section> -->

<section>
  <div class="columns">
    <div class="column is-half">
      <InfoCard icon="info-icon-energy" :data="(areaData[0]+areaData[1]+areaData[2])/1000"/>
    </div>
    <!-- <div class="column">
      <InfoCard icon="info-icon-usage"/>
    </div> -->
  </div>
</section>

<section v-if="!currentData.devices">
  <MainChart :chartData="chartData"/>
</section>
</div>
<div v-else>
  <section style="height: 800px">
    <h2 class="subtitle"> Please add your first Device </h2>
  </section>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src 
import MainChart from '@/components/charts/MainChart.vue'
import UnitCarousel from '@/components/UnitCarousel.vue'
import GraphCard from '@/components/GraphCard.vue'
import InfoCard from '@/components/InfoCard.vue'
//import Slider from '@/components/Slider.vue'
import { getToken } from "../session";


export default {
  name: 'Home',
  components: {
    MainChart,
    UnitCarousel,
    GraphCard,
    InfoCard,
    //Slider
  },
  data() {
    return {
      data: [],
      liveData: [],
      unitID: this.$store.getters.getUid,
      unitIndex: 0,
      day: new Date(),
      today: new Date(),
      calday: new Date(),
      dayNext: 0,
      isLoading: true,
      isLoaded: false,
      unitsInit: false,
      height: window.innerHeight.toString() + 'px',
      unitsRawData: [],
      unitsData: [],
      units: [{"title":"Loading","unit":[2500,500],"image":"/img/2_5_unit_2.7beed16e.png","data":{"power":0,"battery":"0","status":0}}]
    }
  },
  methods: {
    async getData(){
      this.setLoading(true);
       axios
      .get(process.env.VUE_APP_API_BASE + this.currentID + '/data/' + this.dayString, {
        headers: {
          'X-Amz-Security-Token':  await getToken()//this.$store.getters.getToken
        }
      })
      .then(response => (this.data = response.data.Items))
      .then(()=> {this.getTotal()})
      .then(() => {this.setLoading(false)})
      .catch(function (error) {
        console.log(JSON.stringify(error));
        this.setLoading(false);
      }); 
    },
    async getTotal(){
      this.setLoading(true);
       axios
      .get(process.env.VUE_APP_API_BASE + this.currentID + '/data/' + this.dayString + '/total', {
        headers: {
          'X-Amz-Security-Token':  await getToken()//this.$store.getters.getToken
        }
      })
      .then(response => console.log(response))
      //.then(response => (this.total = response.data.Items))
      .then(() => {this.setLoading(false)})
      .catch(function (error) {
        console.log(JSON.stringify(error));
        this.setLoading(false);
      }); 
    },
    async getUser(){
      axios
      .get(process.env.VUE_APP_API_BASE + 'ID/settings/user', {
        headers: {
          'X-Amz-Security-Token':  await getToken()
        }
      })
      .then(response => (this.unitsRawData = response.data.Items[0]))
      .then(this.createUnits)
      .then(this.getLive)
      .catch(function (error) {
        console.log(JSON.stringify(error));
      }); 

    },
    async getLive(){
       axios
      .get(process.env.VUE_APP_API_BASE + this.unitID + '/data/live', {
        headers: {
          'X-Amz-Security-Token':  await getToken()
        }
      })
      .then(response => (this.liveData = response.data.map(item => item.Items)))
      .then(this.isLoading = false)
      .catch(function (error) {
        console.log(JSON.stringify(error));
      }); 
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    moveDay(days) {
      //if ((this.day.toLocaleDateString() != this.today.toLocaleDateString()) && days < 0) { //stop date going into future
      if (!(this.day.toLocaleDateString() == this.today.toLocaleDateString() && days > 0)){
        let dateNext = new Date(this.day); //using setDate() on this.day creates reactivity issues
        dateNext.setDate(dateNext.getDate() + days);
        this.day = dateNext;
      }
    },
    pollData() {
      this.getData();
      setTimeout(() => this.pollData(), 180000);
    },
    pollLiveData() {
      this.getLive();
      setTimeout(() => this.pollLiveData(), 10000);
    },
    dateChanged() { //issue with locale dates use library to resolve?
        let dateNext = new Date(this.calday); //using setDate() on this.day creates reactivity issues
        dateNext.setHours(dateNext.getHours() + 4);        
        this.day = dateNext;
    },
    unitSelected(index){
      this.unitIndex = index;
      this.getData();
    },
    unitImage(type) {
      if(type[0] == 2500) {
        return require('@/assets/2_5_unit_2.png');
      }
      else {
        return require('@/assets/5_unit.png');
      }
    },
    createUnits () {
      this.units = this.unitsData; //this can be removed?
    //  console.log('Units created:')
    //     if (this.unitsData.length > 1) {
    //       let unitsDataAll = this.unitsData;
    //       unitsDataAll.push({
    //         all: true,
    //         title: 'All Devices',
    //         data: {
    //           power: this.totalPower,
    //           //usage: 1245, //this is total usage for all devices card
    //           devices: this.unitsData.length
    //         }
    //       });
    //       this.units = unitsDataAll;
    //     }
    //     else {
    //       this.units = this.unitsData;
    //     }      
    },
  },
  computed: {
    currentID () {
      return this.unitID[this.unitIndex];
    },
    currentTitle () {
      if (this.unitsData.length>0) {
          return this.units[this.unitIndex].title;
      }
      else {
        return 'Loading'
      }
    },
    currentSubtitle () {
      if (this.unitsData.length>0) {
       let unit = this.units[this.unitIndex].unit;
       return unit ? `POWERQUAD PRO ${unit[0]/1000} KWH` : '';
      }
      else {
        return 'Loading'
      }
    },
    currentData () {
      if (this.unitsData.length>0) {
        return {"data": this.units[this.unitIndex].data, "config": this.units[this.unitIndex].unit};
      }
      else {
        return 0;
      }
    },
    chartData () {
      let series = [{name: 'Battery Out', data: [0]}, 
                    {name: 'Area 1', data: [0]}, 
                    {name: 'Area 2',  data: [0]}, 
                    {name: 'Battery In',  data: [0]}];

      if (this.data.length>0){
        //move shifting into Lambda?
        series[0].data = this.data.map(item => item.BatMetering.BatteryPowerWatts < 0 ? item.BatMetering.BatteryPowerWatts*-1 : 0);
        series[1].data = this.data.map(item => item.OutputAreaMeterings[1].ChannelPowerWatts);
        series[2].data = this.data.map(item => item.OutputAreaMeterings[2].ChannelPowerWatts);
        series[3].data = this.data.map(item => item.BatMetering.BatteryPowerWatts > 0 ? item.BatMetering.BatteryPowerWatts : 0);
        
        let xaxis =  this.data.map(item => item.DataReadDateTime.replace('day_',''));

        if(this.isLoaded == false){() => {
          // eslint-disable-next-line
          this.liveData = this.data[this.data.length - 1];
          // eslint-disable-next-line
          this.isLoaded = true;
        }
        }

        return [series, xaxis];
      }
      else {
        return [series, ''];
      }
    },
    areaData(){
      //areas =  this.liveData[0].OutputAreaMeterings.map(item => item.ChannelPowerWatts);
      let areas = [];

      // areas[0] = this.chartData[0][0].data.reduce((a, b) => a + (((b/60)*10)/1000), 0) + this.chartData[0][3].data.reduce((a, b) => a + (((b/60)*10)/1000), 0); //calc '_day' W into kwh
      // areas[1] = this.chartData[0][1].data.reduce((a, b) => a + (((b/60)*10)/1000), 0);
      // areas[2] = this.chartData[0][2].data.reduce((a, b) => a + (((b/60)*10)/1000), 0);

      areas[0] = this.data.reduce((a, b) => a + (((b.OutputAreaMeterings[0].ChannelPowerWatts/60)*10)), 0);
      areas[1] = this.data.reduce((a, b) => a + (((b.OutputAreaMeterings[1].ChannelPowerWatts/60)*10)), 0);
      areas[2] = this.data.reduce((a, b) => a + (((b.OutputAreaMeterings[2].ChannelPowerWatts/60)*10)), 0);

      return areas;
    },
    liveChartData () {
      let power = [0];
      let battery = [0];

      if (this.liveData.length>0){
        power = [this.liveData[0].OutputAreaMeterings[1].ChannelPowerWatts + 
                this.liveData[0].OutputAreaMeterings[2].ChannelPowerWatts];
        battery = [this.liveData[0].BatMetering.ChargePercentage.toFixed(2)];
      }
      
      return {power: power , battery: battery}
    },
    dayString() {
      return this.day.toISOString().split('T')[0];
    },
    // totalPower() {
    //   return this.unitsData.reduce((a, b) => a + (b.data.power || 0), 0)
    // }
  },
  watch: {
    day () {
      this.getData(); //could add debounce here
      this.calday = this.day; //locale fix - remove when resolved
    },
    test () {
      if (this.test > 1) {
        this.test = -2;
      }
    },
    unitsRawData() {
        
      this.unitsData = this.unitsRawData.UnitData.map(item => ({
          title: item.name,
          unit: item.config,
          image: this.unitImage(item.config),
          data: {power: 0, battery: 0, status: 0 },
      }));

      //console.log(this.unitsRawData.UnitID);

      this.$store.commit(
          "SET_UID",
          this.unitsRawData.UnitID
        );  

      // if (this.unitsRawData.UnitID.length > 0) {
          
      // } 

    },
    liveData () {
      this.liveData.forEach((item, index) => {
        if (item.length) {
          try {
          this.unitsData[index].data.power = Math.round(item[0].OutputAreaMeterings[1].ChannelPowerWatts + 
                                                 item[0].OutputAreaMeterings[2].ChannelPowerWatts);
          this.unitsData[index].data.battery = item[0].BatMetering.ChargePercentage.toFixed();
          let status = 0; //-2 = offline, -1 = discharging, 0 = not charging, 1 = charging
          if (item[0].ChargerStatus) { 
            status = 1
          }
          else if (item[0].OutputAreaMeterings[0].status) {
            status = -1
          }
          this.unitsData[index].data.status = status;
          } catch (e) {
            console.log('error');
          }

        } else { //unit is offline
          this.unitsData[index].data.battery = 0;
          this.unitsData[index].data.power = 0;
          this.unitsData[index].data.status = -2;
        }
        //console.log(JSON.stringify(this.unitsData[index]))
      });

      // Total power from API
      // if (this.unitsData.length > 1) {
      //   this.units[this.units.length-1].data.power = this.totalPower;
      // }
    },
  },
  mounted () {
    // this.$store.commit(
    //       "SET_UID",
    //       []
    //     );
    this.pollData();
    this.pollLiveData();
    //setTimeout(() => this.pollLiveData(), 10000);
  },
  created() {
    this.getUser();
    //now covered in App.js
    // axios.interceptors.request.use((config) => {
    //   if (this.$store.getters.getIsExpired) {
    //     this.router.push({ path: 'login' })
    //   }
    //   return config;
    // }, (error) => {
    //   this.isLoading = false;
    //   return Promise.reject(error);
    // });
    
    // axios.interceptors.request.use((config) => {
    //   if (this.isLoaded == false) {
    //     this.isLoading = true;
    //     this.isLoaded = true;
    //   }
    //   return config;
    // }, (error) => {
    //   this.isLoading = false;
    //   return Promise.reject(error);
    // });

    // axios.interceptors.response.use((response) => {
    //   this.isLoading = false;
    //   return response;
    // }, (error) => {
    //   this.isLoading = false;
    //   return Promise.reject(error);
    // });

  }

}
</script>

<style scoped>
.battery-gauge {
  width: 100%;
  height: 100%;
}
.chart-container {
  height: 50vh;
}

.datepicker.control input {
	border-color: white !important;
	box-shadow: none !important;
}

UnitCarousel {
  height: 250px;
}

section{
  margin-top: 5rem;
}

.home {
  background: linear-gradient(to bottom, white 0%, white 10%, rgb(245,247,250) 10%,rgb(245,247,250) 100%); 
}
 
</style>

<style>
 
  .datepicker.control input {
    border-color: white !important;
    box-shadow: none !important;
    width: 200px;
  }

  .datepicker.control i {
    color: #9c9c9c !important;
  }
  /* .input{
   border-color: white !important;
 } */
</style>
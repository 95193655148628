<template>
  <div id="chart">
    <apexchart width="65px" height="65px" type="radialBar" :options="chartOptions" :series="data"></apexchart>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data: function() {
    return {
      series: [70],
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false          
              },
              value: {
                show: false
              }
            },
            hollow: {
              margin: 0,
              size: "55",
              image: require('@/assets/bolt.png'),
              imageWidth: 30,
              imageHeight: 30,
              imageClipped: false
            },
            track: {
              background: '#eaedf0'
            }                       
          },
        },
        fill: {
          colors: ['#46c9f8']
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15,
            left: -15,
            right: -15,
          }
        } 
      }
    }
  }
}
</script>
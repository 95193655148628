<template>    
    <div>
        <apexchart type="area" :height="height" :options="options" :series="series" class="chart-fullheight"></apexchart>
        <!-- <apexchart type="area" :height="heightFix" :options="options" :series="chartData[0]" class="chart-fullheight"></apexchart>  -->
    </div>
</template>

<script>
export default {
  name: 'MainChart',
  props: ['chartData', 'height'],
  data: function() {
    return {
      heightFix: document.documentElement.clientHeight*0.49,//'auto',//window.innerHeight*0.55,
      series: this.chartData[0],
      options: {
        chart: {
          height: 450
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top'
        },
        xaxis: {
          type: 'datetime',
          categories: this.chartData[1]
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val.toFixed(2);
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        stroke: {
          width: 2.5,
          curve: 'smooth',
          lineCap: 'round'
        },
        markers: {
          size: 4,
          strokeOpacity: 0.1,
        }
      }
    }
  },
  watch: {
    chartData () {
      this.series = this.chartData[0];
      this.options = {
      ...this.options,
      ...{
        xaxis: {
          type: 'datetime',
          categories: this.chartData[1]
        },
      },
    };
    }
  }

}
</script>

<style scoped>
.chart-fullheight{ 
  height: 100%;
}
</style>

<template>
<div class="card">
  <div class="level p-5">
    <div class="level-left">
      <div class="level-item graph-card">
          <div v-if="type == 'power'">
            <PowerGauge  :data='data'/>
          </div>
          <div v-if="type == 'battery'">
            <BatteryGauge :data='data'/>
          </div>
          <div v-if="type == 'status'">
            <StatusGauge :data='data'/> <!--dev-->
          </div>
          <div v-if="type == 'usage'">
            <BatteryGauge :data='100'/>
          </div>
          <div v-if="type == 'devices'">
            <StatusGauge :data='0'/>
          </div>
      </div>
      <div class="level-item">
        <div>
          <p class="heading is-capitalized">{{type}}</p>
          <p v-if="type != 'status'" class="title">{{data[0]}}{{unit}}</p>
          <div v-else class="level">
            <div class="level-item">
              <div class="title">{{status}}</div>
            </div>
            <div class="level-item pt-1">
              <div>{{remainingChargetime}}</div>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>    
</template>

<script>
import BatteryGauge from '@/components/charts/BatteryGauge.vue'
import PowerGauge from '@/components/charts/PowerGauge.vue'
import StatusGauge from '@/components/charts/StatusGauge.vue'

export default {
  name: 'GraphCard',
  props: ['type', 'unit', 'data', 'config'],
  components: {BatteryGauge, PowerGauge, StatusGauge},
  data() {
    return {
      radioButton: ''
    }
  },
  computed: {
    status(){
      let val = this.data.data.status;
      let status = '';
      if (val == -1) {
        status = 'Discharging';
      }
      else if (val == 0) {
        status = 'Not Charging';
      }
      else if (val == 1) {
        status = 'Charging';
      }
      else if (val == -2) {
        status = 'Offline';
      }
      return status;
    },
    remainingChargetime () {
      if (this.data.data.battery < 100 && this.status == "Charing") {
        let timeRemaining = (this.data.config[0]/this.data.config[1])*(1-(this.data.data.battery/100));
        let hrsRemainaing = parseInt(String(timeRemaining), 10);
        let minsRemaining = (60 * (timeRemaining-hrsRemainaing)).toFixed(0);
        if (minsRemaining<10) { minsRemaining = "0" + minsRemaining}
        return "~ " + hrsRemainaing + "hr" + minsRemaining;
      } else {
        return '';
      }
    }
  },
}
</script>

<style scoped>
.graph-card {
  min-height: 75px;
}
</style>

 <template>
  <div class="card">
    <div class="level p-5">
      <div class="level-left">
        <div class="level-item">
            <figure class="image ">
              <img :src="image" alt="Placeholder image">
            </figure>
        </div>
        <div class="level-item">
          <div>
            <p class="heading">Usage Today</p>
            <p class="title">{{data.toFixed(2)}}kWh</p>
          </div>
        </div>
      </div>
    </div>
  </div>        
</template>

<script>
export default {
    props:['icon', 'data'],
    data() {
        return {
            radioButton: ''
        }
    },
    computed:{
      image(){
        return require('@/assets/'+ this.icon +'.png')
      }
    }
}
</script>

<template>
    <b-carousel-list v-model="test" :data="unitList" :items-to-show="3">
        <template #item="list">

            <div v-if="list && !list.all" class="card pq-card mr-3 is-clickable" @click="change(list.index)" :class="{active: list.index == selected }">
               <div class="card-content">
                  <div class="media">
                      <div class="media-left">
                          <figure class="image ">
                              <img :src="list.image" alt="Placeholder image">
                          </figure>
                      </div>
                      <div class="media-content mr-2">
                          <p class="title is-4">{{list.title}} </p>
                          <p class="subtitle is-7 is-uppercase">powerQuad Pro {{list.unit[0]/1000}}kWh</p>
                          <div class="level mb-1">
                              <div class="level-left">
                                  <div class="level-item">
                                    <b-image :src="require('@/assets/unit-card-power.png')"></b-image>
                                  </div>
                                  <div class="level-item mr-2">
                                    Power
                                  </div>
                                  <div class="level-item has-text-weight-semibold">
                                    {{list.data.power}}W
                                  </div>
                              </div>
                          </div>
                          <div class="level mb-1">
                            <div class="level-left">
                              <div class="level-item">
                                <b-image :src="require('@/assets/unit-card-battery.png')"></b-image>
                              </div>
                              <div class="level-item mr-2">
                                Battery
                              </div>
                              <div class="level-item has-text-weight-semibold">
                                {{list.data.battery}}%
                              </div>
                            </div>
                          </div>
                          <div class="level">
                            <div class="level-left">
                              <div class="level-item has-text-weight-semibold">
                                <!-- <b-image v-if="status(list.data.status) == 'Charging'" :src="require('@/assets/unit-card-status-charging.png')"></b-image>
                                <b-image v-else-if="status(list.data.status) == 'Discharging'" :src="require('@/assets/unit-card-status-discharging.png')"></b-image>
                                <b-image v-else :src="require('@/assets/unit-card-status.png')"></b-image> -->
                                <b-image :src="require('@/assets/unit-card-status.png')"></b-image>
                              </div>
                              <div class="level-item mr-2">
                                Status
                              </div>
                              <div class="level-item has-text-weight-semibold">
                                {{status(list.data.status)}}
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
               </div>
            </div>

            <div v-else-if="list" class="card pq-card mr-3 is-clickable" @click="change(list.index)" :class="{active: list.index == selected }">
              <div class="card-content">
                <div class="media">
                    <div class="media-left">
                        <figure class="image ">
                            <img :src="require('@/assets/multi_unit_1.png')" alt="Placeholder image">
                        </figure>
                    </div>
                    <div class="media-content mr-2">
                        <p class="title is-4 pb-3">All Devices </p>
                        <p class="subtitle is-7 is-uppercase"> &#32; </p>
                        <div class="level mb-1">
                            <div class="level-left">
                                <div class="level-item">
                                  <b-image :src="require('@/assets/unit-card-power.png')"></b-image>
                                </div>
                                <div class="level-item mr-2">
                                  Power
                                </div>
                                <div class="level-item has-text-weight-semibold">
                                  <!-- {{[].concat(...untis).reduce((a, { invoicedNet: { amountString }}) => a + amountString, 0)}} W -->
                                  {{list.data.power}} W
                                </div>
                            </div>
                        </div>
                        <!-- <div class="level mb-1">
                          <div class="level-left">
                            <div class="level-item">
                              <b-image :src="require('@/assets/unit-card-battery.png')"></b-image>
                            </div>
                            <div class="level-item mr-2">
                              Usage
                            </div>
                            <div class="level-item has-text-weight-semibold">
                              1245kWh
                            </div>
                          </div>
                        </div> -->
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item has-text-weight-semibold">
                              <b-image :src="require('@/assets/unit-card-status.png')"></b-image>
                            </div>
                            <div class="level-item mr-2">
                              Devices
                            </div>
                            <div class="level-item has-text-weight-semibold">
                              {{listLength}}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>

        </template>
        
    </b-carousel-list>
</template>

<script>
export default {
  props:['units'],
   data() {
    return {
      test: 0,
      selected: 0,
      loadingUnit: {
            title: 'Loading...',
            data: {
              power: 0,
              battery: 0,
              status: 0
            }
          }
    };
  },
  methods: {
    info(value) {
      this.test = value;
    },
    status(val){
      let status = '';
      if (val == -1) {
        status = 'Discharging';
      }
      else if (val == 0) {
        status = 'Not Charging';
      }
      else if (val == 1) {
        status = 'Charging';
      }
      else if (val == -2) {
        status = 'Offline';
      }
      return status;
    },
    change(value) {
      this.selected = value;
      this.$emit('unitSelected', value)
    }
  },
  computed: { 
    unitList() {
      if (this.listLength){
        return this.units.concat(this.totalCard);
      } {
        return this.units;
      }
    },
    totalCard() {
      let totalCard = [];
      if (this.listLength > 1) {
        let unitsTotal = this.units.reduce((a, b) => a + (b.data.power || 0), 0);
        totalCard = {
            all: true,
            title: 'All Devices',
            data: {
              power: unitsTotal,
              //usage: 1245, //this is total usage for all devices card
              devices: this.listLength
            }
        }
      }
      return totalCard;
    },
    listLength() {
      return this.units.length;
    }   
  }
};
</script>

<style scoped>
img {
  max-width: 125px;
}
.carousel-list.has-shadow {
	box-shadow: none;
}
.info-image {
  max-width: 25px;
}
.active{
	border: 1px solid rgb(70,200,249);
}
.pq-card {
  margin: 14px;
}
.media {
    min-height: 178px;
}
@media screen and (max-width: 1400px){
  .title.is-4 {
    font-size: 1.25rem;
  }
  .media {
    flex-direction: column;
    min-height: 298px;
  }
}
</style>



 
<template>
  <div id="chart">
    <apexchart width="60px" height="60px" type="radialBar" :options="chartOptions" :series="series"></apexchart>
  </div>

  <!-- <svg
  class="progress-ring"
  height="60"
  width="60"
>
  <circle
    stroke-width="2"
    stroke="#eaedf0"
    fill="transparent"
    r="29"
    cx="30"
    cy="30"
  />
</svg> -->

<!-- <div class="status-gauge">
  <b-image v-show="opacity(-2)" :src="require('@/assets/status-1.png')"  ></b-image>
  <b-image v-show="opacity(-1)" :src="require('@/assets/status-2-glow.png')" ></b-image>
  <b-image v-show="opacity(0)" :src="require('@/assets/status-3-glow.png')" ></b-image>
</div> -->

</template>

<script>
export default {
  props: ['data'],
  data: function() {    
    return {
      //configs Offline, Discharging, Not Charging, Charging
      configs: [{val: 0, color: '#ab30fc'},{val: 100, color: '#ab30fc'},{val: 0, color: '#30fc41'},{val: 100, color: '#30fc41'}],
      series: [0],
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false          
              },
              value: {
                show: false
              }
            },
            hollow: {
              margin: 0,
              size: "63%",
              image: require('@/assets/status.png'),
              imageWidth: 30,
              imageHeight: 30,
              imageClipped: false
            },
            track: {
              background: '#eaedf0'
            }                     
          },
        },
        grid: {
          padding: {
            top: -14,
            bottom: -14,
            left: -14,
            right: -14,
          }
        },
        fill: {
          colors: ['#46c9f8']
        }
      }
    }
  },
  methods: {
    opacity(val) {
      if (val == this.data) {
        return 1
      } else {
        return 0
      }
    }
  },
  watch: {
    data () {
      this.series = [this.configs[this.data+2].val]; //+2 turns status into array position
      this.chartOptions = {
      ...this.chartOptions,
      ...{
        fill: {
          colors: [this.configs[this.data+2].color]
        },
      },
    };
    }
  },
  computed: {
    source(){
      return require('@/assets/status-' + (this.data + 3) + '.png')
    }
  }
  
}
</script>

<style scoped>
/* .status-gauge {
  width:60px; 
  margin-right: 10px;
  transition: all 1s;
} */
</style>